/* eslint-disable */
import {
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { AndroidLogo, AppleLogo, WindowsLogo } from "components/icons/Icons";
import Menu from "components/menu/MainMenu";
import React, { useMemo, useState, useEffect } from "react";
import * as XLSX from 'xlsx';
import * as ExcelJS from 'exceljs';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {tableDataCheckOne} from "views/admin/dataTables/variables/columnsData";
import store from "../../../../stores/store";
import { observer } from 'mobx-react';

const DevelopmentTable = observer((props) => {
  const { columnsData, tableData } = props;

  const [excelData, setExcelData] = useState([]);

  const [headers, setHeaders] = useState(0);
  let [totalNumberViews, setTotalNumberViews] = useState(0);
  let [viewsPerAd, setViewsPerAd] = useState(0);
  let [numberWorking, setNumberWorking] = useState(0);
  let [numberViewsPerOneJob, setNumberViewsPerOneJob] = useState(0);
  let [averagePriceAdvertisements, setAveragePriceAdvertisements] = useState(0);

  const [promotions, setPromotions] = useState(0);
  const [viewsBust, setViewsBust] = useState(0);
  const [amountBust, setAmountBust] = useState(0);
  const [countBustView, setCountBustView] = useState(0);
  const [amountOneBust, setAmountOneBust] = useState(0);

  const [promotionsNoBust, setPromotionsNoBust] = useState(0);
  const [viewsNoBust, setViewsNoBust] = useState(0);
  const [countViewsNoBust, setCountViewsNoBust] = useState(0);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
      console.log(sheet);
      setExcelData(sheet);
    };
    reader.readAsArrayBuffer(file);
  };

  const parsingHeaders = () => {
    const newHeaders = [];
    let lengthHeaders = 0;

    excelData.forEach((row) => {
      // Получите значение ячейки в столбце "Заголовок" (предполагая, что это столбец с индексом 0)
      const header = row[2];
      // Добавьте значение в массив headers
      newHeaders.push(header);
    });
    console.log(newHeaders.length - 1);
    lengthHeaders = newHeaders.length - 1;
    console.log(lengthHeaders);
    setHeaders(lengthHeaders);
  }

  const parsingTotalNumberViews = () => {
    let sum = 0;

    excelData.forEach((row) => {
      // Получите значение ячейки в столбце "Заголовок" (предполагая, что это столбец с индексом 0)
      const header = row[4];
      // Добавьте значение в массив headers

      if (!isNaN(header))
        sum += parseInt(header);
    });

    setTotalNumberViews(sum);
  }

  const parsingViewsPerAd = () => {
    let sum = totalNumberViews / (headers);

    setViewsPerAd(sum);
  }

  const parsingNumberWorking = () => {
    const data = [];

    excelData.forEach((row) => {
      // Получите значение ячейки в столбце "Заголовок" (предполагая, что это столбец с индексом 0)
      const header = row[4];
      // Добавьте значение в массив headers
      data.push(header);
    });

    const filteredData = data.filter(value => value > 0);
    const count = filteredData.length;

    setNumberWorking(count);
  }

  const parsingNumberViewsPerOneJob = () => {
    let sum = totalNumberViews / numberWorking;

    setNumberViewsPerOneJob(sum);
  }

  const parsingAveragePriceAdvertisements = () => {
    const data = [];

    excelData.forEach((row) => {
      // Получите значение ячейки в столбце "Заголовок" (предполагая, что это столбец с индексом 0)
      const header = parseInt(row[5]);
      // Добавьте значение в массив headers

      if (!isNaN(header))
        data.push(header);
    });

    const d4Value = headers;

    const sum = data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const result = d4Value !== 0 ? sum / d4Value : 0;

    setAveragePriceAdvertisements(result);
  }

  //2 table

  const parsingPromotions = () => {
    const newHeaders = [];

    excelData.forEach((row) => {
      const header = row[7];

      if (header)
        newHeaders.push(header);
    });

    const filledCellsCount = newHeaders.length - 1;
    setPromotions(filledCellsCount);
  }

  const sumIfEmpty = () => {
    const sum = excelData.reduce((total, row) => {
      if (row[7]) {
        return total + (parseFloat(row[4]) || 0);
      }
      return total;
    }, 0);

    setViewsBust(sum);
  }

  const viewAmountBust = () => {
    const sum = excelData.reduce((acc, curr) => {
      if (typeof curr[8] === 'number') {
        return acc + curr[8];
      } else {
        return acc;
      }
    }, 0);

    setAmountBust(sum);
  }

  const parseCountBustView = () => {
    const sum = viewsBust/promotions;
    setCountBustView(sum);
  }

  const parseAmountOneBust = () => {
    const sum = amountBust/promotions;
    setAmountOneBust(sum);
  }

  //3 table

  const parsingPromotionsNoBust = () => {
    const sum = numberWorking - promotions;
    setPromotionsNoBust(sum);
  }
  const sumIfNoEmpty = () => {
    const sum = excelData.reduce((total, row) => {
      if (!row[7]) {
        return total + (parseFloat(row[4]) || 0);
      }
      return total;
    }, 0);

    setViewsNoBust(sum);
  }

  const parsingCountViewsNoBust = () => {
    const sum = viewsNoBust/promotionsNoBust;
    setCountViewsNoBust(sum);
  }

  useEffect(() => {

    const dataGeneralAnalitic = [{
      "name":["Всего объявлений в парсинге",false],
      "quantity": 0
    },
      {
        "name":["Общее количество просмотров",true],
        "quantity": 0
      },
      {
        "name":["Просмотров на одно объявление (от общей массы)",true],
        "quantity": 0
      },
      {
        "name":["Количество работающих объявлений",true],
        "quantity": 0
      },
      {
        "name":["Количество просмотров на 1 рабочее объявление",false],
        "quantity": 0
      },
      {
        "name":["Средняя цена в объявлениях",false],
        "quantity": 0
      }
    ];

    const dataBustAnalitic = [{
      "name":["Количество объявлений с бустом",false],
      "quantity": 0
    },
      {
        "name":["Сумма просмотров на объявленияx с бустом",true],
        "quantity": 0
      },
      {
        "name":["Стоимость 1 просмотра на объявлениях с бустом",true],
        "quantity": 0
      },
      {
        "name":["Сумма затрат на услуги продвижения",true],
        "quantity": 0
      },
      {
        "name":["Кол-во просм на 1 объяв с бустом",false],
        "quantity": 0
      },
      {
        "name":["Стоимость поднятия на 1 объявление в среднем",false],
        "quantity": 0
      }
    ];

    const dataNoBustAnalitic = [{
      "name":["Количество объявлений без буста",false],
      "quantity": 0
    },
      {
        "name":["Сумма просмотров на объявлениях без буста",true],
        "quantity": 0
      },
      {
        "name":["Стоимость 1 просмотра на объявлениях без буста",true],
        "quantity": 0
      },
      {
        "name":["Сумма затрат на просмотры",true],
        "quantity": 0
      },
      {
        "name":["Количество просмотров на 1 объявление без буста",false],
        "quantity": 0
      }
    ];

    const countPublic = [{
      "name":["Публикуем на профиль в день",false],
      "quantity": 0
    },
      {
        "name":["Применяем бустов (шт)",true],
        "quantity": 0
      },
      {
        "name":["Цена с макс трафиком Мин",true],
        "quantity": 0
      },
      {
        "name":["Цена с макс трафиком Макс",true],
        "quantity": 0
      }
    ];

    parsingHeaders();
    parsingTotalNumberViews();
    parsingViewsPerAd();
    parsingNumberWorking();
    parsingNumberViewsPerOneJob();
    parsingAveragePriceAdvertisements();

    parsingPromotions();
    sumIfEmpty();
    viewAmountBust();
    parseCountBustView();
    parseAmountOneBust();

    parsingPromotionsNoBust();
    sumIfNoEmpty();
    parsingCountViewsNoBust();

    dataGeneralAnalitic[0].quantity = headers;
    dataGeneralAnalitic[1].quantity = totalNumberViews;
    dataGeneralAnalitic[2].quantity = viewsPerAd;
    dataGeneralAnalitic[3].quantity = numberWorking;
    dataGeneralAnalitic[4].quantity = numberViewsPerOneJob;
    dataGeneralAnalitic[5].quantity = averagePriceAdvertisements;

    dataBustAnalitic[0].quantity = promotions;
    dataBustAnalitic[1].quantity = viewsBust;
    dataBustAnalitic[3].quantity = amountBust;
    dataBustAnalitic[4].quantity = countBustView;
    dataBustAnalitic[5].quantity = amountOneBust;

    dataNoBustAnalitic[0].quantity = promotionsNoBust;
    dataNoBustAnalitic[1].quantity = viewsNoBust;
    dataNoBustAnalitic[4].quantity = countViewsNoBust;

    store.setTableDataCheckOne(dataGeneralAnalitic);
    store.setBustAnalitic(dataBustAnalitic);
    store.setNoBustAnalitic(dataNoBustAnalitic);

  }, [excelData, parsingHeaders, parsingTotalNumberViews, parsingViewsPerAd, parsingNumberWorking, parsingNumberViewsPerOneJob, parsingAveragePriceAdvertisements]);

  return (
    <Card
      direction='column'
      w='100%'
      h='745px'
      px='0px'
      overflowX={{ sm: "scroll", lg: "scroll" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Результаты парсинга
        </Text>

        <Input type="file" size="md" className='chakra-input css-5rf251' onChange={handleFileUpload} />

        <Menu />
      </Flex>
      <Table {...getTableProps()} variant='striped' color='gray.500' mb='24px'>
        {!!excelData.length && (<Thead>
          {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {excelData[0].forEach((column, index) => (
                    <Th
                        pe='10px'
                        key={index}
                        borderColor={borderColor}>
                      <Flex
                          justify='space-between'
                          align='center'
                          fontSize={{sm: "10px", lg: "12px"}}
                          color='gray.400'>
                      </Flex>
                    </Th>
                ))}
              </Tr>
          ))}
        </Thead>)}
        <Tbody {...getTableBodyProps()}>
          {excelData.map((row, index) => (
              <Tr key={index}>
                {row.map((cell, cellIndex) => (
                    <Td key={cellIndex}>{cell}</Td>
                ))}
              </Tr>
          ))}

        </Tbody>
      </Table>
    </Card>
  );
})

export default DevelopmentTable;
