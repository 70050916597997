export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  }
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export let tableDataCheckOne = [{
  "name":["Всего объявлений в парсинге",false],
  "quantity": 0
},
  {
    "name":["Общее количество просмотров",true],
    "quantity": 0
  },
  {
    "name":["Просмотров на одно объявление (от общей массы)",true],
    "quantity": 0
  },
  {
    "name":["Количество работающих объявлений",true],
    "quantity": 0
  },
  {
    "name":["Количество просмотров на 1 рабочее объявление",false],
    "quantity": 0
  },
  {
    "name":["Средняя цена в объявлениях",false],
    "quantity": 0
  }
];
