import { makeAutoObservable } from 'mobx';

class MyStore {
    // Инициализация переменных состояния
    tableDataCheckOne = [{
        "name":["Всего объявлений в парсинге",false],
        "quantity": 0
    },
        {
            "name":["Общее количество просмотров",true],
            "quantity": 0
        },
        {
            "name":["Просмотров на одно объявление (от общей массы)",true],
            "quantity": 0
        },
        {
            "name":["Количество работающих объявлений",true],
            "quantity": 0
        },
        {
            "name":["Количество просмотров на 1 рабочее объявление",false],
            "quantity": 0
        },
        {
            "name":["Средняя цена в объявлениях",false],
            "quantity": 0
        }
    ];

     bustAnalitic = [{
         "name":["Количество объявлений с бустом",false],
         "quantity": 0
     },
         {
             "name":["Сумма просмотров на объявленияx с бустом",true],
             "quantity": 0
         },
         {
             "name":["Стоимость 1 просмотра на объявлениях с бустом",true],
             "quantity": 0
         },
         {
             "name":["Сумма затрат на услуги продвижения",true],
             "quantity": 0
         },
         {
             "name":["Кол-во просм на 1 объяв с бустом",false],
             "quantity": 0
         },
         {
             "name":["Стоимость поднятия на 1 объявление в среднем",false],
             "quantity": 0
         }
     ];

    noBustAnalitic = [{
        "name":["Количество объявлений без буста",false],
        "quantity": 0
    },
        {
            "name":["Сумма просмотров на объявлениях без буста",true],
            "quantity": 0
        },
        {
            "name":["Стоимость 1 просмотра на объявлениях без буста",true],
            "quantity": 0
        },
        {
            "name":["Сумма затрат на просмотры",true],
            "quantity": 0
        },
        {
            "name":["Количество просмотров на 1 объявление без буста",false],
            "quantity": 0
        }
    ];

    constructor() {
        makeAutoObservable(this); // Автоматическая обёртка для наблюдаемых переменных
    }

    // Методы для изменения переменных состояния
    setTableDataCheckOne(data) {
        this.tableDataCheckOne = data;
    }

    setBustAnalitic(data) {
        this.bustAnalitic = data;
    }

    setNoBustAnalitic(data) {
        this.noBustAnalitic = data;
    }
}

const store = new MyStore(); // Создание экземпляра стора
export default store;